@import 'firebaseui/dist/firebaseui.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* .backdrop-saturate-150 {
    width: 50vw !important;
} */

:root {
  --font-heading1: 'Montserrat', sans-serif;
  --font-heading: "Roboto", "Roboto Placeholder", sans-serif;
  --font-heading2: "Inter",sans-serif;
  --font-heading3: "DM Sans",sans-serif;
}

body {
  font-family: var(--font-heading3);
}

.about_us {
  margin-top: 50px;
  border-radius: 8px;
  background-image: url('https://images.unsplash.com/photo-1518481852452-9415b262eba4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}

.firebaseui-id-dialog {
  top: -200px !important;
  width: 200px !important;
  height: 90px !important;
}

.thumbsup-icon {
  position: relative;
  width: 270px;
  height: 150px;
}

.thumbsup-icon svg {
  position: absolute;
}

.thumbsup-icon .stripes {
  fill: #f7f7f7;
  animation: 1300ms stripes-swing infinite ease-in-out;
}

.thumbsup-icon .stars {
  fill: #febb02;
}

.thumbsup-icon .star1 {
  animation: 1300ms star-fly1 infinite;
  z-index: 1;
}

.thumbsup-icon .star2 {
  animation: 1300ms star-fly2 infinite;
  z-index: 1;
}

.thumbsup-icon .star3 {
  animation: 1300ms star-fly3 infinite;
  z-index: 1;
}

.thumbsup-icon .star4 {
  animation: 1300ms star-fly4 infinite;
  z-index: 1;
}

.thumbsup-icon .star5 {
  animation: 1300ms star-fly5 infinite;
  z-index: 1;
}

.thumbsup-icon .thumbsup {
  animation: 1300ms thumbsup-shake infinite ease-in-out;
  z-index: 2;
}

@keyframes star-fly1 {
  0% {
    transform: translate(20%, 40%) scale(0.76, 0.76);
    opacity: 0;
  }

  12.8% {
    opacity: 1;
  }

  28.2% {
    transform: translate(0, 0) scale(1, 1);
  }
}

@keyframes star-fly2 {
  0% {
    transform: translate(-14%, 40%) scale(0.76, 0.76);
    opacity: 0;
  }

  12.8% {
    opacity: 1;
  }

  28.2% {
    transform: translate(0, 0) scale(1, 1);
  }
}

@keyframes star-fly3 {
  0% {
    transform: translate(5%, -35%) scale(0.76, 0.76);
    opacity: 0;
  }

  12.8% {
    opacity: 1;
  }

  28.2% {
    transform: translate(0, 0) scale(1, 1);
  }
}

@keyframes star-fly4 {
  0% {
    transform: translate(30%, 0) scale(0.76, 0.76);
    opacity: 0;
  }

  12.8% {
    opacity: 1;
  }

  28.2% {
    transform: translate(0, 0) scale(1, 1);
  }
}

@keyframes star-fly5 {
  0% {
    transform: translate(-20%, 0) scale(0.76, 0.76);
    opacity: 0;
  }

  12.8% {
    opacity: 1;
  }

  28.2% {
    transform: translate(0, 0) scale(1, 1);
  }
}

@keyframes thumbsup-shake {
  0% {
    transform: rotate(20deg) scale(0.74, 0.74);
    opacity: 0.1;
  }

  7.7% {
    opacity: 1;
    transform: rotate(0) scale(1, 1);
  }

  15.4% {
    transform: rotate(-11deg) scale(1.08, 1.08);
  }

  28.2% {
    transform: rotate(0) scale(1, 1);
  }
}

@keyframes stripes-swing {
  20.5% {
    transform: translate(4%, 0);
  }

  38.4% {
    transform: translate(-2%, 0);
  }

  59% {
    transform: translate(3.7%, 0);
  }

  79.5% {
    transform: translate(0, 0);
  }
}

.alreadyApplied {
  border-radius: 50% !important;
}

@-webkit-keyframes pulse {

  0%,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes pulse {

  0%,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.animate__pulse {
  /* -webkit-animation-name: pulse !important;
  animation-name: pulse ;
  -webkit-animation-timing-function: ease-in-out !important;
  animation-timing-function: ease-in-out !important;
  animation-iteration-count: infinite !important; */
  animation: pulse linear both infinite;
}

.JobCard {
  position: relative;
}

.infoIcon {
  position: absolute;
  top: 10px;
  right: 12px;
  padding: 4px 7px;
  z-index: 11;
}

.videotag {
  z-index: 0;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.HomeBtn:hover {
  box-shadow: 0px 3px 5px 0px #cecece;
}

.SubmitBtn:hover {
  background-color: transparent;
  border: 2px solid #cecece;
  /* box-shadow: 0px 5px 5px 0px #000; */
}

.videotag img {
  width: 100%;
  height: 100%;
}

/* Tailwind's responsive utilities will handle most of it, but here is an optional rule */
@media (max-width: 768px) {
  .navbar {
    position: static; /* Disable sticky behavior for mobile if needed */
  }
}

/* .navbars {
  position: fixed;
} */

.dot {
  height: .5em;
  width: .5em;
  border-radius: 50%;
  background-color: #989898;
}

.dot.active.light {
  background-color: #000 !important;
}

.dot.active.dark {
  background-color: #fff !important;
}

.searchBox{
  border: 3px solid #e4e4e7;
  border-radius: 15px;
}

.searchBoxDark{
  border: 3px solid #3f3f46;
  border-radius: 15px;
}

.textlight{
  color: #6b6b6b;
}
.textdark{
  color: #171717;
}
.textdarkmain{
  color: #c3c3c3;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}

.shadow-accent-volume {
  --tw-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25), inset 2px 2px 6px #000000, inset -5px -5px 10px #000000;
  --tw-shadow-colored: 5px 5px 10px var(--tw-shadow-color), inset 2px 2px 6px var(--tw-shadow-color), inset -5px -5px 10px var(--tw-shadow-color);
}
.shadow-\[0_5px_0_0_\#ef4444\], .shadow-accent-volume {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.font-semibold {
  font-weight: 600;
}


.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.bg-accent {
  --tw-bg-opacity: 1;
  background-color: black;
}
.rounded-full {
  border-radius: 9999px;
}
a {
  color: inherit;
  text-decoration: inherit;
}
.space-x-4>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem* var(--tw-space-x-reverse));
  margin-left: calc(1rem* calc(1 - var(--tw-space-x-reverse)));
}

.shadow-white-volume {
  --tw-shadow: 5px 5px 10px rgba(108, 106, 212, .25), inset 2px 2px 6px #eef1f9, inset -5px -5px 10px #dfe3ef;
  --tw-shadow-colored: 5px 5px 10px var(--tw-shadow-color), inset 2px 2px 6px var(--tw-shadow-color), inset -5px -5px 10px var(--tw-shadow-color);
}
.shadow-sm, .shadow-white-volume {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-accent {
  --tw-text-opacity: 1;
  color: rgb(59 130 246/var(--tw-bg-opacity,1));
}
.font-semibold {
  font-weight: 600;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.rounded-full {
  border-radius: 9999px;
}
a {
  color: inherit;
  text-decoration: inherit;
}
.animate-marqueeRight {
  animation: marqueeRight 15s linear infinite;
}
.mb-8 {
  margin-bottom: 2rem;
}
.p-6 {
  padding: 1.5rem;
}
.border-jacarta-100 {
  --tw-border-opacity: 1;
  border-color: rgb(231 232 236 / var(--tw-border-opacity));
}
.border {
  border-width: 1px;
}
.rounded-2\.5xl {
  border-radius: 1.25rem;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.animate-marqueeRight {
  animation: marqueeRight 15s linear infinite;
}
@keyframes marqueeRight {
  0% {
    transform: translateX(-2322px);
  }
  to {
    transform: translateX(0);
  }
}
.animate-marqueeRight {
  animation: marqueeRight 15s linear infinite;
}
.animate-marquee {
  animation: marquee 60s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-2322px);
  }
}
.animate-marquee {
  animation: marquee 60s linear infinite;
}
.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}
.to-\[\#F5F8FA_33\%\] {
  --tw-gradient-to: #f5f8fa 33% var(--tw-gradient-to-position);
}
.from-\[transparent_33\%\] {
  --tw-gradient-from: transparent 33% var(--tw-gradient-from-position);
  --tw-gradient-to: hsla(0, 0%, 100%, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
@media (min-width: 1025px) {
  .lg\:justify-between {
      justify-content: space-between;
  }
}

@media (min-width: 1025px) {
  .lg\:flex {
      display: flex;
  }
}
@media (min-width: 1025px) {
  .lg\:w-\[45\%\] {
      width: 45%;
  }
}
.relative {
  position: relative;
}
@media (min-width: 1025px) {
  .lg\:pl-24 {
      padding-left: 6rem;
  }
}
@media (min-width: 1025px) {
  .lg\:w-\[55\%\] {
      width: 55%;
  }
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.text-jacarta-700 {
  --tw-text-opacity: 1;
  color: rgb(19 23 64 / var(--tw-text-opacity));
}
.text-3xl {
  font-size: 2rem;
  line-height: normal;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
  margin: 0;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-center {
  background-position: 50%;
}
.bg-cover {
  background-size: cover;
}

.z-10 {
  z-index: 10;
}

.text-3xl {
  font-size: 2rem;
  line-height: normal;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
  margin: 0;
}
h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}
.text-jacarta-300 {
  --tw-text-opacity: 1;
  color: rgb(161 162 179 / var(--tw-text-opacity));
}
.text-lg {
  font-size: 1.25rem;
  line-height: 1.5;
}
.max-w-xl {
  max-width: 36rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
  margin: 0;
}
@media (min-width: 768px) {
  .md\:pl-8 {
      padding-left: 2rem;
  }
}

.pl-2 {
  padding-left: .5rem;
}
.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}
.border-jacarta-200 {
  --tw-border-opacity: 1;
  border-color: rgb(196 197 207 / var(--tw-border-opacity));
}
.border-l-2 {
  border-left-width: 2px;
}
.self-start {
  align-self: flex-start;
}
.w-1\/3 {
  width: 33.333333%;
}
menu, ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .md\:w-2\/4 {
      width: 50%;
  }
}
@media (min-width: 768px) {
  .md\:mt-16 {
      margin-top: 4rem;
  }
}
.pl-4 {
  padding-left: 1rem;
}

.tab-content>.active {
  display: block;
}
.tab-content>.tab-pane {
  display: none;
}
.fade {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  transition-timing-function: linear;
}
.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.py-4x {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.bg-light-base {
  --tw-bg-opacity: 1;
  background-color: rgb(245 248 250 / var(--tw-bg-opacity));
}
.max-w-xl {
  max-width: 36rem;
}
.mb-20 {
  margin-bottom: 5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.text-jacarta-700 {
  --tw-text-opacity: 1;
  color: rgb(19 23 64 / var(--tw-text-opacity));
}
.font-medium {
  font-weight: 500;
}
.text-3xl {
  font-size: 2rem;
  line-height: normal;
}
.text-center {
  text-align: center;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
  margin: 0;
}
.text-lg {
  font-size: 1.25rem;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .md\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.gap-7 {
  gap: 1.75rem;
}
.grid {
  display: grid;
}
.transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}

.pt-0 {
  padding-top: 0;
}
.p-8 {
  padding: 2rem;
}
.border-jacarta-100 {
  --tw-border-opacity: 1;
  border-color: rgb(231 232 236 / var(--tw-border-opacity));
}

.rounded-2\.5xl {
  border-radius: 1.25rem;
}

.rounded-full {
  border-radius: 9999px;
}


.w-\[5\.5rem\] {
  width: 5.5rem;
}
.h-\[5\.5rem\] {
  height: 5.5rem;
}
.inline-flex {
  display: inline-flex
;
}
.mb-9 {
  margin-bottom: 2.25rem;
}
.-mt-8 {
  margin-top: -2rem;
}
.text-jacarta-700 {
  --tw-text-opacity: 1;
  color: rgb(19 23 64 / var(--tw-text-opacity));
}
.text-lg {
  font-size: 1.25rem;
  line-height: 1.5;
}
.mb-4 {
  margin-bottom: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}


.w-\[5\.5rem\] {
  width: 5.5rem;
}
.h-\[5\.5rem\] {
  height: 5.5rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.swiper-button-next,
.swiper-button-prev {
	color: #333 !important;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
	color: #666 !important;
}

.testimonial-section .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	bottom: 0 !important;
	left: 0 !important;
	width: 100% !important;
	/* border: 1px solid red !important; */
	z-index: 10;
}

.testimonial-section .swiper-wrapper,
.characters-section .swiper-wrapper,
.features-section .swiper-wrapper,
.financial-section .swiper-wrapper {
	margin-bottom: 2rem;
}

.testimonial-section .swiper-pagination-bullet,
.characters-section .swiper-pagination-bullet,
.features-section .swiper-pagination-bullet,
.financial-section .swiper-pagination-bullet {
	/* background-color: rgba(196, 197, 207, 1) !important; */
	/* background-color: white; */
	background-color: rgba(196, 197, 207, 1);
	opacity: 1 !important;
	border-radius: 9999px;
	cursor: pointer;
	display: inline-block;
	height: 0.625rem;
	margin-left: 0.25rem;
	margin-right: 0.25rem;
	width: 0.625rem;
}

.dark .testimonial-section .swiper-pagination-bullet,
.dark .characters-section .swiper-pagination-bullet,
.dark .features-section .swiper-pagination-bullet,
.dark .financial-section .swiper-pagination-bullet,
.dark .benifit-section .swiper-pagination-bullet {
	background-color: white;
	opacity: 1 !important;
}
.swiper-pagination-bullet-active,
.swiper-pagination-bullet:focus,
.swiper-pagination-bullet:hover {
	opacity: 1 !important;
	background-color: rgb(59 130 246/var(--tw-bg-opacity,1)) !important;
	height: 0.625rem;
	margin-left: 0.25rem;
	margin-right: 0.25rem;
	width: 0.625rem;
}

.border-b {
	border-bottom-width: 0px;
}

/* Accordions */
.accordion-button {
	overflow-anchor: none;
}

.max-w-sm {
  max-width: 24rem;
}

.swiper-horizontal {
  touch-action: pan-y;
}
.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
}
.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}

.p-12 {
  padding: 3rem;
}

.rounded-2\.5xl {
  border-radius: 1.25rem;
}
.gap-5 {
  gap: 1.25rem;
}

.flex-wrap {
  flex-wrap: wrap;
}
.rounded-2\.5xl {
  border-radius: 1.25rem;
}
.self-start {
  align-self: flex-start;
}
.w-28 {
  width: 7rem;
}



.mt-10 {
  margin-top: 2.5rem;
}

.swiper-pagination-bullet {
  margin-left: .25rem;
  margin-right: .25rem;
  display: inline-block;
  height: .625rem;
  width: .625rem;
  cursor: pointer;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(196 197 207 / var(--tw-bg-opacity));
}

.fill-orange {
  fill: #feb240;
}

.w-5 {
  width: 1.25rem;
}
.h-5 {
  height: 1.25rem;
}

.container1 {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}

@media (min-width: 575px) {
  .container1 {
    max-width: 575px;
  }
}

@media (min-width: 768px) {
  .container1 {
    max-width: 768px;
  }
}

@media (min-width: 1025px) {
  .container1 {
    max-width: 1025px;
  }
}

@media (min-width: 1202px) {
  .container1 {
    max-width: 1202px;
  }
}


.container2 {
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}

@media (min-width: 575px) {
  .container2 {
    max-width: 575px;
  }
}

@media (min-width: 768px) {
  .container2 {
    max-width: 768px;
  }
}

@media (min-width: 1025px) {
  .container2 {
    max-width: 1025px;
  }
}

@media (min-width: 1202px) {
  .container2 {
    max-width: 1202px;
  }
}
@media (min-width: 1025px) {
  .container3 {
      max-width: 100%;
  }
}

.container3 {
  width: 100%;
}

@media (min-width: 575px) {
  .container3 {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container3 {
    max-width: 100%;
  }
}

@media (min-width: 1025px) {
  .container3 {
    max-width: 100%;
  }
}

@media (min-width: 1202px) {
  .container3 {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .slid {
    height: 0px !important;
  }
}

@media (max-width: 768px) {
  .custslid {
    height: 50% !important;
  }
}

@media (max-width: 768px) {
  .heightcustom {
    height: 50vh !important;
  }
}

.custombgg{
  background-color: rgb(0 9 51 / var(--tw-bg-opacity, 1)) !important;
}
@media (min-width: 1025px) {
  .lg\:pt-48 {
      padding-top: 12rem;
  }
}

.pt-20 {
  padding-top: 5rem;
}
.pb-32 {
  padding-bottom: 8rem;
}
.-z-10 {
  z-index: -10;
}
.top-0 {
  top: 0;
}
.inset-x-0 {
  left: 0;
  right: 0;
}
.absolute {
  position: absolute;
}
.pointer-events-none {
  pointer-events: none;
}
.hidden {
  display: none;
}
.right-0 {
  right: 0;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.max-w-\[91rem\] {
  max-width: 91rem;
}
.h-full {
  height: 100%;
}
.mr-auto {
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}
.w-full {
  width: 100%;
}
img, video {
  max-width: 100%;
  height: auto;
}

@media (min-width: 1025px) {
  .lg\:grid-cols-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
@media (min-width: 1025px) {
  .lg\:grid {
      display: grid;
  }
}
.gap-4 {
  gap: 1rem;
}

@media (min-width: 1025px) {
  .lg\:py-20 {
      padding-top: 5rem;
      padding-bottom: 5rem;
  }
}
@media (min-width: 1025px) {
  .lg\:items-start {
      align-items: flex-start;
  }
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}


.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-6 {
  grid-column: span 6 / span 6;
}
@media (min-width: 1025px) {
  .lg\:text-right {
      text-align: right;
  }
}

@media (min-width: 1025px) {
  .lg\:pl-32 {
      padding-left: 8rem;
  }
}
@media (min-width: 768px) {
  .md\:left-20 {
      left: 5rem;
  }
}
@media (min-width: 575px) {
  .sm\:left-\[5\%\] {
      left: 5%;
  }
}
.shadow-2xl {
  --tw-shadow: 0px 25px 50px -12px rgba(13, 16, 45, .1), 0px 12px 24px 0px rgba(13, 16, 45, .1);
  --tw-shadow-colored: 0px 25px 50px -12px var(--tw-shadow-color), 0px 12px 24px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.bg-\[\#EAF7E1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 247 225 / var(--tw-bg-opacity));
}
.rounded-2lg {
  border-radius: .625rem;
}
.inline-block {
  display: inline-block;
}
.top-1\/3 {
  top: 33.333333%;
}
.-left-\[18\%\] {
  left: -18%;
}
.rounded-full {
  border-radius: 9999px;
}


.w-12 {
  width: 3rem;
}
.h-12 {
  height: 3rem;
}

.rounded-2\.5xl {
  border-radius: 1.25rem;
}
.inline-block {
  display: inline-block;
}

audio, canvas, embed, iframe, img, object, svg, video {
  display: block;
  vertical-align: middle;
}
.fill-green {
  fill: #10b981;
}
.text-green {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}
.text-3xl {
  font-size: 2rem;
  line-height: normal;
}
.font-display {
  font-family: CalSans-SemiBold, sans-serif;
}
.block {
  display: block;
}
.text-jacarta-600 {
  --tw-text-opacity: 1;
  color: rgb(54 58 93 / var(--tw-text-opacity));
}
.fill-red {
  fill: #ef4444;
}
.top-6 {
  top: 1.5rem;
}
.right-6 {
  right: 1.5rem;
}
.animate-fly {
  animation: fly 6s cubic-bezier(.75,.02,.31,.87) infinite;
  animation-duration: 6s;
  animation-timing-function: cubic-bezier(0.75, 0.02, 0.31, 0.87);
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: fly;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
}

/* @media (min-width: 768px) {
  .mmd {
      margin-left: 13%;
  }
} */

@media (max-width: 640px){
  .vieww {
    display: none !important;
  }
}

.container4 {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}

@media (min-width: 575px) {
  .container4 {
    max-width: max-content;
  }
}

@media (min-width: 768px) {
  .container4 {
    max-width: max-content;
  }
}

@media (min-width: 1025px) {
  .container4 {
    max-width: max-content;
  }
}

@media (min-width: 1202px) {
  .container4 {
    max-width: max-content;
  }
}
@media (min-width: 1500px) {
  .container4 {
    max-width: max-content;
  }
}
.firebaseui-list-box-dialog {
max-width: 90% !important;
}

.firebaseui-id-dialog {
  top: 0 !important;
  width: 200px !important;
  height: auto !important;
}
/* Light Mode */
.section-gradient {
  background: url(https://konnectsquare.com/images/gradient.jpg);
}

/* Dark Mode */
html.dark .section-gradient {
  background: url(https://konnectsquare.com/images/gradient_dark.jpg);
}

.sticky-right-section {
  position: sticky;
  top: 10px; /* Adjust top value as needed */
  align-self: flex-start; /* Ensures it stays aligned at the top */
  z-index: 10; /* Ensure it stays above other content */
  background-color: white; /* Optional: add a background color for visibility */
}